import React from 'react';
import External from '../../../assets/images/svg/external.svg';
import * as styles from './link-to.module.scss';

export function LinkTo( { href, children } ) {
  return (
    <span className={ styles.linkTo }>
      <a href={ href } rel="noopener noreferrer" target="_blank">
        { children }
      </a>
      <External className={ styles.linkToIcon } />
    </span>
  );
}