import React from 'react';
import Section from '../../../layout/Section/Section';
import { ShaderCarousel } from '../../../components/ShaderCarousel/ShaderCarousel';
import * as styles from './premium-header.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'gatsby';
import ArrowAlt from '../../../../assets/images/svg/arrow-alt.svg';

export function PremiumHeader() {
  const { t } = useTranslation( 'premium' );

  return (
    <Section className={ styles.section } isWide as={ 'header' }>
      <div className={ styles.wrapper }>
        <div className={ styles.carousel }>
          <ShaderCarousel options={ { heightRatio: 0.5625, pagination: false } } />
        </div>

        <div className={ styles.content }>
          <header className={ styles.header }>
            <p>Released!</p>
            <h1>Splide Premium</h1>
          </header>
          <div className={ styles.desc }>
            <p>
              <Trans ns="premium" i18nKey="mainDescription1">
                Enrich your next project with <span>impressive transition effects by Splide and WebGL (three.js).</span>
              </Trans>
            </p>
            <p>{ t( 'mainDescription2' ) }</p>
          </div>
          <div className={ styles.link }>
            <Link to={ '#details' }>Details <ArrowAlt /></Link>
          </div>
        </div>
      </div>
    </Section>
  );
}