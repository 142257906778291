import React from 'react';
import Section from '../../../layout/Section/Section';
import { ShaderCarousel } from '../../../components/ShaderCarousel/ShaderCarousel';
import * as styles from './premium-examples.module.scss';
import { dissolveShader } from '@splidejs/splide-shader-carousel';


export function PremiumExamples() {
  return (
    <Section id="demo" isNarrow>
      <Example
        heading={ 'Fiber Mask' }
        options={ {
          mask: '/images/masks/fiber01.jpg',
          autoplay: false,
          heightRatio: 0.5625,
          pagination : false,
        } }
      />

      <Example
        heading={ 'Radial Gradient Mask' }
        options={ {
          mask: '/images/masks/radialGradient01.jpg',
          autoplay: false,
          heightRatio: 0.5625,
          pagination : false,
        } }
      />

      <Example
        heading={ 'Wave Mask' }
        options={ {
          mask: '/images/masks/wave01.jpg',
          autoplay: false,
          heightRatio: 0.5625,
          pagination : false,
        } }
      />

      <Example
        heading={ 'Cloud Mask' }
        options={ {
          mask: '/images/masks/cloud01.jpg',
          autoplay: false,
          heightRatio: 0.5625,
          pagination : false,
        } }
      />

      <Example
        heading={ 'Dissolve' }
        shader={ dissolveShader }
        options={ {
          autoplay: false,
          material: {
            uvOffset: [ 0, 1.0 ],
            intensity: 1.5,
          },
          heightRatio: 0.5625,
          pagination : false,
        } }
      />
    </Section>
  );
}

function Example( { heading, shader, options = {} } ) {
  return (
    <section className={ styles.example }>
      <h2 className={ styles.heading }>{ heading }</h2>
      <ShaderCarousel shader={ shader } options={ options } hasTexts />
    </section>
  );
}