import React from 'react';
import Layout from '../components/layout/Layout/Layout';
import { PremiumHeader } from '../components/scenes/Premium/PremiumHeader/PremiumHeader';
import { PremiumExamples } from '../components/scenes/Premium/PremiumExamples/PremiumExamples';
import { PremiumDescription } from '../components/scenes/Premium/PremiumDescription/PremiumDescription';
import { SEO } from '../components/seo/SEO/SEO';
import Serif from '../assets/fonts/splide-serif/SplideSerif.woff2';


export default function PremiumPage() {
  return (
    <Layout>
      <PremiumHeader />
      <PremiumExamples />
      <PremiumDescription />
    </Layout>
  );
}

export function Head() {
  return (
    <>
      <SEO
        title={ 'Premium' }
        description={ 'Enrich your next project with impressive transition effects by Splide and WebGL (three.js).' }
        isArticle
      />
      <link rel="preload" href="/images/slides/full/01.webp" as="image" />
      <link rel="preload" href={ Serif } as="font" type="font/woff2" crossOrigin="anonymous" />
    </>
  );
}